<template>
	<div class="box">


		<div class="box-l">
			<transition name="el-fade-in-linear">
				<el-menu router class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :default-active="defaultMenu"
				 :collapse="isCollapse" background-color="#24272B" active-text-color="#FFCC66" text-color="#fff">
					<el-submenu :index="item.name.toString()" v-for="(item,index) of leftlist" :key="index">
						<template slot="title">
							<i :class="item.icon"></i>
							<span slot="title">{{item.name}}</span>
						</template>

						<el-menu-item :index="item2.name.toString()" v-for="(item2,index2) of item.itemList" :key="index2">{{item2.title}}</el-menu-item>

					</el-submenu>
				</el-menu>
			</transition>
		</div>

		<transition name="el-fade-in-linear">
			<div class="box-r" :class="[isCollapse? 'R-left1' : 'R-left2' ]">
				<el-row class="top" type="flex" align="middle" justify="space-between" :class="[isCollapse? 'R-left1' : 'R-left2' ]">
					<div style="display: flex;align-items: center;">

						<i class="el-icon-s-fold" v-if="isCollapse==false" @click="isCollapsebtn(true)"></i>
						<i class="el-icon-s-unfold" v-if="isCollapse==true" @click="isCollapsebtn(false)"></i>
						<el-breadcrumb separator="/" style="margin-left: 20px;">
							<el-breadcrumb-item v-for="(item,index) of breadcrumbbox" :key="index">
								<router-link :to="item.path||'/memberlist' ">{{ item.meta.title=="用户信息" ? '' : item.meta.title}}</router-link>
							</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
                    <div style="margin-left: 70%;color: dodgerblue; font-weight:900;">欢迎您：{{loginUserRealName}}</div>
					<div @click="logout">退出登录</div>
				</el-row>
				<div class="R-view" :class="[isCollapse? 'R-left1' : 'R-left2' ]">
					<router-view></router-view>
				</div>
			</div>
		</transition>
	</div>

</template>



<script>
	export default {
		data() {
			return {
				isCollapse: false,
				leftlist:[],
                loginUserRealName: '', // 当前登陆人姓名
                list: [{
						name: '会员管理',
						id: 1,
						icon: "el-icon-user",
						item: [{
								name: '会员列表',
								link: '/memberlist',
								id: 2
							},

							{
								name: '预约订单',
								link: '/',
								id: 20
							}

						]

					},
					{
						name: '文章分类',
						id: 3,
						icon: "el-icon-document-copy",
						item: [{
								name: '分类列表',
								link: '/newsmenulist',
								id: 4
							},
							{
								name: '添加分类',
								link: '/newsmenuadd',
								id: 5
							},
						]
					},
					{
						name: '文章管理',
						id: 6,
						icon: "el-icon-document-copy",
						item: [{
								name: '文章列表',
								link: '/newslist',
								id: 7
							},
							// {
							// 	name: '添加文章',
							// 	link: '/newsadd',
							// 	id: 8
							// },
							{
								name: '回收站',
								link: '/newsoutlist',
								id: 9
							},
						]

					},
					{
						name: '医生管理',
						id: 10,
						icon: "el-icon-scissors",
						item: [{
								name: '医生列表',
								link: '/doctorlist',
								id: 11
							},
							{
								name: '添加医生',
								link: '/doctoradd',
								id: 12
							},

						]

					},
					{
						name: '科室管理',
						id: 13,
						icon: "el-icon-office-building",
						item: [{
								name: '科室列表',
								link: '/departmentlist',
								id: 14
							},
							{
								name: '添加科室',
								id: 15,
								link: '/departmentadd'
							},

						]

					},
					// {
					// 	name: '数据库管理',
					// 	id: 14,
					// 	icon: "el-icon-coin",
					// 	item: [{
					// 			name: '数据库备份',
					// 			link: '/sqlbackuo',
					// 			id: 15
					// 		},
					// 		{
					// 			name: '数据库还原',
					// 			id: 16,
					// 			link: '/sqlreduction'
					// 		},

					// 	]

					// },
					{
						name: '管理员',
						id: 17,
						icon: "el-icon-unlock",
						item: [{
								name: '管理员列表',
								link: '/rootadmin',
								id: 18
							},
							{
								name: '用户组列表',
								id: 19,
								link: '/rootuser'
							},

						]

					},

					{
						name: '广告设置',
						id: 21,
						icon: "el-icon-thumb",
						item: [{
								name: '广告列表',
								link: '/plugadlist',
								id: 22
							},
							{
								name: '添加广告',
								id: 23,
								link: '/plugadadd'
							},
							{
								name: '广告分类',
								id: 24,
								link: '/plugadtypelist'
							},

						]

					},

				],
				
				defaultMenu: 'el-icon-user',
				breadcrumbbox: []
			}
		},
		
		
		// 44	/plugadlist	广告列表	1	1	0			28	2	20	1450314265
		methods: {
			handleOpen(key, keyPath) {
			},
			handleClose(key, keyPath) {
			},
			// 获取面包屑
			getbreadcrumb() {
				const that = this;
				// console.log(this.$route.matched, 'this.$route.matchedthis.$route.matchedthis.$route.matched’')
				that.breadcrumbbox = this.$route.matched;
				that.defaultMenu = that.$route.path;
				if (this.$route.matched.length == 1) {
					that.$router.push({
						name: 'memberlist'
					})

				} else if (this.$route.matched.length != 1) {
					var title = this.$route.matched[1].meta.title;
					window.document.title = title;

				}

			},
			// 点击
			isCollapsebtn(e) {
				const that = this;
				that.isCollapse = e;
			},
			// 退出登录
			logout() {
				const that = this;
				this.$confirm('确定退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear();
					that.$router.replace({
						name: 'log'
					})
				}).catch(() => {

				});
			},
			// 获取左侧列表
			getleftlist(){
				const that=this;
				var adminId=localStorage.getItem('userId')||'1';
				that.api('/admin/menuList',{adminId:adminId}).then(res=>{
					that.leftlist=res.data;
				})
			}
			


		},

		watch: {
			$route() {
				const that = this;
				that.getbreadcrumb();
			}
		},

		created() {
			const that = this;
			// that.$router.push({name: 'memberlist'})
			// console.log(that.$route);
            that.loginUserRealName = localStorage.getItem('loginUserRealName')
            that.getbreadcrumb();
			that.getleftlist();
		}
	};
</script>





<style lang="less" scoped>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
		height: 100%;
	}

	.el-menu {
		width: 100%;
	}


	.box {
		.box-l {
			position: fixed;
			left: 0;
			top: 0;
			bottom: 0;
			background-color: #24272B;
			overflow-y: auto;
		}

		.box-r {
			position: fixed;
			right: 0;
			bottom: 0;
			top: 0;
			box-sizing: border-box;

			.top {
				position: fixed;
				top: 0;
				right: 0;
				height: 50px;
				box-sizing: border-box;
				box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
				align-items: center;
				box-sizing: border-box;
				padding: 0 20px;
				z-index: 66;
			}

			.R-view {
				position: fixed;
				top: 50px;
				right: 0;
				bottom: 0;
				box-sizing: border-box;
				padding: 20px;
				overflow-y: auto;

			}

		}

		.R-left2 {
			left: 200px;
		}

		.R-left1 {
			left: 65px;
		}
	}
</style>
